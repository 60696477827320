import React from "react";
import { InstantSearch, connectStateResults } from "react-instantsearch-dom";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import CustomAutocomplete from "./Search/CustomAutocomplete";
import CustomHits from "./Search/CustomHits";

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: process.env.TYPESENSE_API_KEY,
    nodes: [
      {
        host: process.env.TYPESENSE_HOST,
        port: process.env.TYPESENSE_PORT,
        protocol: process.env.TYPESENSE_PROTOCOL,
      },
    ],
  },
  additionalSearchParameters: {
    queryBy: "title,body,paragraph_text,paragraph_highlighted,paragraph_card",
  },
})

const searchClient = typesenseInstantsearchAdapter.searchClient

const Results = connectStateResults(
  ({ searchState, searchResults, children }) => {
    if (searchState && searchState.query) {
      if (searchResults && searchResults.nbHits !== 0) {
        return (
          <>{children}</>
        )
      } else {
        return (
            <><p className="panel-block">Aucun résultat ne correspond à votre recherche.</p></>
        )
      }
    } else {
      return (
        <></>
      )
    }
  }
);

export default function SearchBar() {
  return (
    <div className="search-autocomplete-wrapper">
      <InstantSearch searchClient={searchClient} indexName="lumes_content">
        <CustomAutocomplete />
        <div className="search-autocomplete-result panel has-background-white has-text-black">
          <Results>
            {/*<Stats />*/}
            <CustomHits />
            {/*<RefinementList attribute="content_type" />*/}
          </Results>
        </div>
      </InstantSearch>
    </div>
  )
}
