import React from 'react'
import { isLoggedIn, logout } from '../../services/auth'

export const Logout = ({ preHeader }) => {
    const handleLogout = () => {
        logout()
    }
    return (
        isLoggedIn() && (
            <li className={`logout ${!preHeader && ('navigation')}`}>
                <a onClick={handleLogout}
                   className={`menu-link has-text-white icon is-medium ${preHeader && ('pre-header')}`}><i
                    className="fa fa-sign-out-alt"></i>Déconnexion</a>
            </li>)
    )
}