import React, { useState, useEffect } from "react";
import { connectAutoComplete } from "react-instantsearch-dom";

const Autocomplete = ({ hits, currentRefinement, refine }) => {
    const isBrowser = typeof window !== "undefined"

    const placeholderSmall = "Rechercher…"
    const placeholderLarge = "Rechercher un service, une information…"

    const [placeholder, setPlaceholder] = useState(placeholderSmall);

    const handleResize = () => {
        if (window.innerWidth <= 900) {
            setPlaceholder(placeholderSmall)
        } else {
            setPlaceholder(placeholderLarge)
        }
    }

    if (isBrowser) {
        window.addEventListener('resize', handleResize)
    }

    useEffect(handleResize, [])

    return (
      <div className="control has-icons-right search-autocomplete">
          <input
            type="search"
            value={currentRefinement}
            onChange={event => refine(event.currentTarget.value)}
            className="input is-large is-rounded ds-input"
            placeholder={placeholder}
          />
          <span className="icon is-large is-right search-autocomplete-icon">
              <i className="fas fa-search" aria-hidden="true"></i>
          </span>
      </div>
    )
}

export default connectAutoComplete(Autocomplete);
