import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";

const OpeningHours = ({title, titleDisplay, titleSize}) => {
  const data = useStaticQuery(graphql`{
      block: blockContentBasic(drupal_internal__id: {eq: 3}) {
          info
          body {
              value
          }
      }
  }`)

  return (
    <>
      {titleDisplay && (
        <h2 className={`title is-uppercase is-${titleSize}`}>{title
          ? title
          : data.block.info}</h2>
      )}
      <div className="content">
        <div dangerouslySetInnerHTML={{__html: data.block.body.value}} />
      </div>
    </>
  )
}

OpeningHours.propTypes = {
  title: PropTypes.string,
  titleDisplay: PropTypes.bool,
  titleSize: PropTypes.number,
}

OpeningHours.defaultProps = {
  title: '',
  titleDisplay: true,
  titleSize: 3,
}

export default OpeningHours
