import React, { useState } from "react";
import MainMenu from "./Menus/MainMenu";
import Branding from "./Branding";
import { Logout } from './Private/Logout';

const Navigation = ({color, spaced, ...props}) => {
  const [open, setOpen] = useState(false)

  if (props.className === undefined) {
    props.className = ''
  }

  props.className += ' navbar'

  if (color !== undefined) {
    props.className += ' is-' + color
  }

  if (spaced) {
    props.className += ' is-spaced'
  }

  return (
    <nav {...props}>
      <div className="container">
        <div className="navbar-brand">
          <Branding isNavbar logo logoHeight={"62px"} logoWidth={"155px"}/>
          <button
            className={`button is-primary navbar-burger burger${open
              ? ` is-active`
              : ``}`}
            aria-label="menu"
            aria-expanded={open ? `true` : `false`}
            data-target="navbarCollapsible"
            onClick={() => { setOpen(!open) }}
            style={{borderRadius: '0'}}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
          <Logout/>
        </div>
        <div id="navbarCollapsible"
             className={`navbar-menu${open ? ` is-active` : ``}`}>
          <div className="navbar-end">
            <MainMenu/>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
