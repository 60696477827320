import React from "react";
import PropTypes from "prop-types";
import SearchBar from "./SearchBar";
import SecondaryMenu from "./Menus/SecondaryMenu";
import Title from "./Title";

const Hero = ({title, subtitle, size, color, backgroundImage}) => {
  // Default prop set here cause size & color will always exist but sometimes null
  if (null === size) {
    size = "medium"
  }

  if (null === color) {
    size = "primary"
  }

  return (
    <section
      className={`hero is-${color} is-${size}`}
      style={backgroundImage ? {'backgroundImage': `url(/${backgroundImage})`} : ''}
    >
      <div className="hero-body">
        {(title || subtitle) && (
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-8 has-text-centered">
                  <Title title={title} subtitle={subtitle}/>
                  <SearchBar />
                  <SecondaryMenu />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

Hero.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  size: PropTypes.string,
  backgroundImage: PropTypes.string
}

export default Hero
