import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import MenuLink from "../MenuLink";
import {iconInvisibleText} from "../../Utils/functions";
import { Logout } from '../Private/Logout';

const PreHeaderMenu = () => {
    const data = useStaticQuery(graphql`{
      preHeaderMenu: allMenuLinkContentMenuLinkContent(
          filter: {enabled: {eq: true},
              menu_name: {eq: "pre-header"}},
          sort: {fields: weight, order: ASC}
      ) {
          nodes {
              title
              link {
                  uri
                  options {
                      attributes {
                          data_has_icon
                          data_icon
                          data_icon_size
                          data_text_invisible
                          target
                      }
                      container_attributes {
                        class
                      }
                  }
              }
          }
      }
  }`)

    const menuTitle = (title) => (
        <span className="menu-title">{title}</span>
    )

    return (
        <ul id="pre-header--menu"
            className="menu is-inline is-vcentered">
            {data.preHeaderMenu.nodes.map((menu, index) => (
                <li className={menu.link.options.container_attributes ? `menu-item ${menu.link.options.container_attributes.class}` : `menu-item`}
                    key={index}>
                    <MenuLink internalId={menu.link.uri}
                              className="menu-link has-text-white" {...menu.link.options.attributes}>
                        {menu.link.options.attributes &&
                        menu.link.options.attributes.data_has_icon ? (
                            <>
                                <span
                                    className={`icon ${menu.link.options.attributes.data_icon_size}`}>
                                    <i className={menu.link.options.attributes.data_icon}></i>
                                </span>
                                {menu.link.options.attributes.data_text_invisible
                                    ? iconInvisibleText(menu.title)
                                    : menuTitle(menu.title)}
                            </>
                        ) : menuTitle(menu.title)}
                    </MenuLink>
                </li>
            ))}
            <Logout preHeader={true}/>
        </ul>
    )
}

export default PreHeaderMenu
