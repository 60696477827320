import React, {useContext, useEffect, useRef} from "react";
import {ModalContext} from "../../contexts/ModalContext";

const Modal = () => {
    const {modalVisibility, setModalVisibility, modalContent, setModalContent, setModalKeyDownEvent, modalKeyDownEvent} = useContext(ModalContext)

    const modalRef = useRef(null)

    useEffect(() => {
        let body = document.body

        if (modalVisibility) {
            modalRef.current.focus()
            body.classList.add("is-clipped")
        } else {
            body.classList.remove("is-clipped")
            setModalContent(null)
        }
    }, [modalVisibility])

    useEffect(() => {
        if (null !== modalKeyDownEvent && typeof modalKeyDownEvent !== 'undefined') {
            switch (modalKeyDownEvent.key) {
                case "Escape":
                    setModalVisibility(false)
                    break;
            }
        }
    }, [modalKeyDownEvent])

    return (
        <div
            ref={modalRef}
            className={`modal-overlay ${modalVisibility ? `is-block` : `is-hidden`}`}
            onKeyDown={setModalKeyDownEvent}
            tabIndex={0}
            onClick={() => setModalVisibility(false)}
        >
            <div
                className="modal-container"
                onClick={event => event.stopPropagation()}
            >
                <div className="modal-content">
                    {modalContent}
                </div>
            </div>
            <div
                className="close"
                onClick={() => setModalVisibility(false)}
            >
                <i className="fas fa-times">
                    <span className="is-hidden">Fermer</span>
                </i>
            </div>
        </div>
    )
}

export default Modal
