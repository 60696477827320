import React from "react";
import PropTypes from "prop-types";
import "../styles/index.scss";
import Hero from "./Hero";
import Navigation from "./Navigation";
import FooterMenu from "./Menus/FooterMenu";
import ContactInfos from "./Blocks/ContactInfos";
import OpeningHours from "./Blocks/OpeningHours";
import PreHeaderMenu from "./Menus/PreHeaderMenu";
import WhiteLogoSvg from "../images/logo-white.svg";
import {Flash} from "./Flash";
import ModalProvider from '../contexts/ModalContext';
import Modal from './Modal/Modal';

const Layout = ({children, title, subtitle, heroSize, contentType }) => {
  return (
    <>
      <ModalProvider>
        {contentType && (
            <div data-typesense-field="content_type" style={{display: 'none'}}>{contentType}</div>
        )}
        <div id="region-pre-header" className="region region-pre-header has-background-primary">
          <div className="container">
            <PreHeaderMenu/>
          </div>
        </div>
        <div id="region-primary-menu" className="region region-primary-menu">
          <Navigation id={`main-navigation`}/>
        </div>
        <Hero title={title} subtitle={subtitle} size={heroSize} backgroundImage="background.jpg"/>
        <main id="main-content" role="main">
          <div id="content--region" className="region content--region">
            {children}
            <Flash />
          </div>
        </main>
        <footer id="footer" className="footer section has-background-dark">
          <div className="container">
            <div className="columns is-desktop">
              <div className="column is-4-desktop">
                <div style={{maxWidth: '230px'}}>
                  <WhiteLogoSvg/>
                </div>
              </div>
              <div className="column is-4-desktop">
                <ContactInfos titleSize={5}/>
              </div>
              <div className="column is-4-desktop">
                <OpeningHours titleSize={5}/>
              </div>
              {/*<div className="column is-3">*/}
              {/*  <h2 className="title is-4">Réseaux sociaux</h2>*/}
              {/*  <SocialMenu/>*/}
              {/*</div>*/}
            </div>
            <div className="region has-text-centered">
              <FooterMenu/>
            </div>
          </div>
        </footer>
        <Modal/>
      </ModalProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  heroSize: PropTypes.string,
  typesense: PropTypes.bool
}

Layout.defaultProps = {
  title: null,
  subtitle: null,
  heroSize: null,
  typesense: false
}

export default Layout
