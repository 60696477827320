import React from "react";
import PropTypes from "prop-types";
import MenuLink from "./MenuLink";
import {iconInvisibleText} from "../Utils/functions";

const DrupalMenuRenderer = ({menu, minLevel, maxLevel, activeIndex, openedIndex, clickHandler}) => {
    let computeMenu = []

    menu.forEach(({item, children, level}, index) => {
        // Skip menu level lower than min level
        if (minLevel !== undefined && level < minLevel) {
            return
        }

        // Skip menu level higher than max level
        if (maxLevel !== undefined && level > maxLevel) {
            return
        }

        // Utility variables
        let isRoot = minLevel !== undefined ? level === minLevel : level === 0
        let hasChildren = maxLevel !== undefined ? (level + 1 <= maxLevel && children.size > 0) : children.size > 0
        let isActive = (index === activeIndex)
        let isOpenable = (isRoot && hasChildren)
        let isOpened = (isOpenable && (index === openedIndex))

        // Base Attributes
        let itemAttributes = item.link.options ? item.link.options.container_attributes : null
        let itemClasses = [`navbar-item`, `level-${level}`]
        let linkAttributes = item.link.options ? item.link.options.attributes : null
        let linkClasses = isRoot ? [`navbar-link`, `level-${level}`] : [`navbar-item`, `level-${level}`]

        // Attributes merge
        if (itemAttributes && itemAttributes.class) {
            itemClasses = itemClasses.concat(itemAttributes.class);
        }

        if (linkAttributes && linkAttributes.class) {
            linkClasses = linkClasses.concat(linkAttributes.class);
        }

        // Active element handling
        if (isActive) {
            linkClasses.push('is-active')
        }

        // Menu open state handling
        if (isOpened) {
            itemClasses.push('is-opened')
        }

        if (hasChildren) {
            itemClasses.push('has-dropdown')
        }

        computeMenu.push(
            hasChildren ? (
                <div className={itemClasses.join(' ')}>
                    <MenuLink
                        key={index}
                        internalId={item.link.uri}
                        attributes={item.link.options ? item.link.options.attributes : null}
                        className={linkClasses.join(' ')}
                        clickHandler={(event) => clickHandler(event, index, isOpenable)}
                    >
                        {linkAttributes && linkAttributes.data_has_icon ? (
                            <>
                                <i className={linkAttributes.data_icon}><span
                                    className="hidden">{item.title}</span></i>
                                {linkAttributes.data_text_invisible
                                    ? iconInvisibleText(item.title)
                                    : (<span className="menu-link-label">{item.title}</span>)}
                            </>
                        ) : (
                            <>{item.title}</>
                        )}
                    </MenuLink>
                    <div className={`navbar-dropdown level-${level}`}>
                        <DrupalMenuRenderer menu={children} minLevel={minLevel} maxLevel={maxLevel} activeIndex={activeIndex} openedIndex={openedIndex} clickHandler={clickHandler}/>
                    </div>
                </div>
            ) : (
                <MenuLink
                    key={index}
                    internalId={item.link.uri}
                    attributes={item.link.options ? item.link.options.attributes : null}
                    className={linkClasses.join(' ')}
                    clickHandler={(event) => clickHandler(event, index, isOpenable)}
                >
                    {linkAttributes && linkAttributes.data_has_icon ? (
                        <>
                            <i className={linkAttributes.data_icon}><span
                                className="hidden">{item.title}</span></i>
                            {linkAttributes.data_text_invisible
                                ? iconInvisibleText(item.title)
                                : (<span className="menu-link-label">{item.title}</span>)}
                        </>
                    ) : (
                        <>{item.title}</>
                    )}
                </MenuLink>
            )
        )
    })

    return (
        <>{computeMenu}</>
    )
}

DrupalMenuRenderer.propTypes = {
    menu: PropTypes.instanceOf(Map).isRequired,
    minLevel: PropTypes.number,
    maxLevel: PropTypes.number,
    activeIndex: PropTypes.string,
    openedIndex: PropTypes.string,
    clickHandler: PropTypes.func
}

export default DrupalMenuRenderer
