import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {cssCleanClass} from '../../Utils/functions';

const HitsContentType = ({contentType}) => {
    let displayType = contentType;
    let [typeType, machineName] = contentType.split('__');

    const nodeTypesData = useStaticQuery(graphql`{
      nodeTypes: allNodeTypeNodeType {
        nodes {
          machineName: drupal_internal__type
          name
        }
      }
    }
  `);

    switch (typeType) {
        case 'node':
            for (let index in nodeTypesData.nodeTypes.nodes) {
                if (machineName ===
                    nodeTypesData.nodeTypes.nodes[index].machineName) {
                    displayType = nodeTypesData.nodeTypes.nodes[index].name;
                }
            }
            break;
        case 'portal':
            displayType = "Portail"
            break;
        default:
            break;
    }

    return (
        <span className={`tag is-small${typeType ? ` type-${cssCleanClass(
            typeType)}` : ``}`}>{displayType}</span>
    );
};

export default HitsContentType;
