import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { iconInvisibleText } from "../../Utils/functions";
import MenuLink from "../MenuLink";

const FooterMenu = () => {
  const data = useStaticQuery(graphql`{
      footerMenu: allMenuLinkContentMenuLinkContent(
          filter: {enabled: {eq: true},
              menu_name: {eq: "footer"}},
          sort: {fields: weight, order: ASC}
      ) {
          nodes {
              title
              link {
                  uri
                  options {
                      attributes {
                          data_has_icon
                          data_icon
                          data_icon_size
                          data_text_invisible
                          target
                      }
                  }
              }
          }
      }
  }`)

  return (
    <ul id="footer--menu" className="menu is-inline is-centered is-vcentered">
      {data.footerMenu.nodes.map((menu, index) => (
        <li className="menu-item" key={index}>
          <MenuLink internalId={menu.link.uri}
                    className="menu-link" {...menu.link.options.attributes}>
            {menu.link.options.attributes &&
            menu.link.options.attributes.data_has_icon ? (
              <>
                <span
                  className={`icon ${menu.link.options.attributes.data_icon_size}`}>
                    <i className={menu.link.options.attributes.data_icon}></i>
                </span>
                {menu.link.options.attributes.data_text_invisible
                  ? iconInvisibleText(menu.title)
                  : menu.title}
              </>
            ) : (
              <>{menu.title}</>
            )}
          </MenuLink>
        </li>
      ))}
      <li className="menu-item">
        <span className="menu-link">
          Une création <Link to="https://www.isics.fr/" title="Isics, expérience digitale" target="_blank">Isics</Link>
        </span>
      </li>
    </ul>
  )
}

export default FooterMenu
