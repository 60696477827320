import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import MenuLink from "../MenuLink";
import {iconInvisibleText} from "../../Utils/functions";

const SecondaryMenu = () => {
    const data = useStaticQuery(graphql`{
    secondaryMenu: allMenuLinkContentMenuLinkContent(
      filter: {enabled: {eq: true},
          menu_name: {eq: "secondary"}},
      sort: {fields: weight, order: ASC}
    ) {
      nodes {
          title
          link {
              uri
              options {
                  attributes {
                      data_has_icon
                      data_icon
                      data_icon_size
                      data_text_invisible
                      target
                  }
                  container_attributes {
                    class
                  }
              }
          }
      }
    }
  }`)

    return (
        <ul id="secondary--menu"
            className="tags are-medium is-centered">
            {data.secondaryMenu.nodes.map((menu, index) => (
                <li className={`tag is-rounded is-primary ${menu.link.options.container_attributes && menu.link.options.container_attributes.class}`}
                    key={index}>
                    <MenuLink internalId={menu.link.uri}
                              className="tag-link" {...menu.link.options.attributes}>
                        {menu.link.options.attributes &&
                        menu.link.options.attributes.data_has_icon ? (
                            <>
                                <span
                                    className={`icon ${menu.link.options.attributes.data_icon_size}`}>
                                    <i className={menu.link.options.attributes.data_icon}></i>
                                </span>
                                {menu.link.options.attributes.data_text_invisible
                                    ? iconInvisibleText(menu.title)
                                    : menu.title}
                            </>
                        ) : (
                            <>{menu.title}</>
                        )}
                    </MenuLink>
                </li>
            ))}
        </ul>
    )
}

export default SecondaryMenu
