import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Logo from '../images/logo.svg';

const Branding = ({
                    logo,
                    logoHeight,
                    logoWidth,
                    title,
                    description,
                    isNavbar,
                  }) => {
  const data = useStaticQuery(graphql`{
      site {
          siteMetadata {
              logo
              title
              description
          }
      }
  }`)

  const sizedLogo = (children) => (
    <div style={{height: logoHeight, width: logoWidth}}>
      {children}
    </div>
  )

  return (
    <>
      {logo && (
        <Link className={`${isNavbar ? `navbar-item ` : ``}site-logo`} to="/"
              title="Accueil">
          {(logoHeight || logoWidth) ? sizedLogo(
            <Logo src={data.site.siteMetadata.logo} className="img" alt="Logo" />
          ) : (
            <Logo src={data.site.siteMetadata.logo} className="img" alt="Logo" />
          )}
        </Link>
      )}
      {title && (
        <Link className={`${isNavbar ? `navbar-item ` : ``}site-name`} to="/"
              title="Accueil">
          <div
            className="title is-4 is-size-6-mobile">{data.site.siteMetadata.title}</div>
        </Link>
      )}
      {description && (
        <Link className={`${isNavbar
          ? `navbar-item is-hidden-mobile `
          : ``}site-description`} to="/" title="Accueil">
          <div className="subtitle">{data.site.siteMetadata.description}</div>
        </Link>
      )}
    </>
  )
}

Branding.propTypes = {
  logo: PropTypes.bool,
  logoHeight: PropTypes.string,
  logoWidth: PropTypes.string,
  title: PropTypes.bool,
  description: PropTypes.bool,
  isNavbar: PropTypes.bool,
}

Branding.defaultTypes = {
  logo: false,
  title: false,
  description: false,
  isNavbar: false,
}

export default Branding
