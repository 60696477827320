import {navigate} from 'gatsby';
import {POST} from '../Utils/appFetch';
import jwt_decode from 'jwt-decode';

/**
 * Use this method for api call always during loading, this method is synchronous if token is expired this method is block during all new token call
 */
export const getToken = async () => {
    if (window.localStorage.getItem('token')) {
        let token = window.localStorage.getItem('token');
        if (!tokenIsValid(token)) {
            // try to refresh
            let tokenAndRefresh = await refreshToken(
                window.localStorage.getItem('refreshToken'));
            setAuthData(tokenAndRefresh ? tokenAndRefresh[0] : null,
                tokenAndRefresh ? tokenAndRefresh[1] : null);
            return tokenAndRefresh ? tokenAndRefresh[0] : null;
        }
    } else {
        return null;
    }
};

export const setAuthData = (token, refreshToken) => {

    if (token !== null) {
        window.localStorage.setItem('token', JSON.stringify(token));
        window.localStorage.setItem('refreshToken',
            JSON.stringify(refreshToken));
    } else {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('refreshToken');
        navigate('/');
        return null;
    }
};

export const isLoggedIn = () => {
    if (typeof window !== "undefined") {
        return !!window.localStorage.getItem('token');
    } else {
        return false;
    }
};

export const logout = () => {
    setAuthData(null, null);
};

export const refreshToken = async (refreshToken) => {
    let formData = new FormData();
    formData.append('grant_type', 'refresh_token');
    formData.append('refresh_token', refreshToken.replace(/"/g,""));
    formData.append('client_id', process.env.CLIENT_ID);
    formData.append('client_secret', process.env.CLIENT_SECRET);
    let response = await fetch(`${process.env.API_URL}/oauth/token`,
        {
            method: POST,
            body: formData,
        });

    try {
        if (response.status === 200) {
            let json = await response.json();
            if (json.access_token) {
                return [json.access_token, json.refresh_token];
            }
        }
        throw response;

    } catch (e) {
        console.log('Something wrong happen during refresh token');
    }
    return null;
};

export const tokenIsValid = (token) => {
    return jwt_decode(token).exp > Date.now().valueOf() / 1000;
};