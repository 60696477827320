import React from "react";
import {Link} from "gatsby";
import { connectHits } from "react-instantsearch-dom";
import CustomHighlight from "./CustomHighlight";
import CustomHitsContentType from "./CustomHitsContentType";

const Hits = ({ hits }) => {
  return (
    <>
      {hits.map(hit => (
        <Link to={hit.page_path} className="panel-block" key={hit.objectID}>
          <CustomHighlight hit={hit} attribute="title" />
          {hit.content_type && (
            <>
              &nbsp;<CustomHitsContentType contentType={hit.content_type} />
            </>
          )}
        </Link>
      ))}
    </>
  )
};

export default connectHits(Hits);
